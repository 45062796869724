﻿const toggleMenuDropdown = () => {
    let navbarMenu = document.getElementById("mainNav");
    navbarMenu.className = navbarMenu.className === 'navbar-menu' ? 'navbar-menu is-active' : 'navbar-menu';
}

let progressBar = document.getElementById("progress-bar");
if(progressBar !== undefined && progressBar !== null){
    function onScroll() {
        progressBar.style.setProperty('--scroll', (100 * document.documentElement.scrollTop / document.documentElement.scrollTopMax) + "%");
    }
    window.onscroll = function () {
        onScroll()
    };
}